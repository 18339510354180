import React from "react";
import styled from '@emotion/styled';
import Layout from '../components/layout';


const ContactForm = styled.div`
    margin:auto;
    padding: 7rem 15%;
    @media (max-width: 767px){
        padding: 7rem 5%;
    }
    form{
        display:flex;
        flex-direction:column;
        align-items:center;
        
        label{
            width:75%;
            text-align:center;
            display:flex;
            margin-bottom:1rem;

            input{
                flex:1;
                border:none;
                padding:1rem;
                border-radius:3px;
                background-color:#ccc;
                appearance: none;
            }
            textarea{
                flex:1;
                height:15rem;
                border-radius:3px;
                background-color:#ccc;
                padding:1rem;
            }
        }
        button{
            padding:1rem;
            width:75%;
            border-radius:3px;
            border:0;
            background-color:var(--s1color);
            color:var(--txcolor);
            font-weight:700;
            font-size:1.7rem;
            transition:all .5s;
            &:hover{
                background-color:var(--s1hcolor);
            }
        }
    }
`;

export default function Contacto() {


    return (

        <Layout>
            <ContactForm>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                        
                    <label><input placeholder='Nombre' type="text" name="name" /></label>
                    <label><input placeholder='Nro de Telefono' type="number" name="phone" /></label>
                    <label><input placeholder='Correo' type="email" name="email" /></label>
                    <label><textarea placeholder='Mensaje' name="message"></textarea></label>
                
                    <button type="submit">Enviar</button>
                    
                </form>
            </ContactForm>
        </Layout>
    )
}
